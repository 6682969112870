import React from 'react';
import Amplify from 'aws-amplify';
import { fetchUtils, Admin, Resource } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import authProvider from './authProvider';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import HouseIcon from '@material-ui/icons/House';

import { 
  SkillList,
  SkillEdit,
  SkillCreate,
} from './skills';
import {
  VerificationList,
  VerificationCreate,
} from './verifications';
import {
  PropertyList,
  PropertyEdit,
  PropertyCreate,
} from './properties';
import {
  PropertyAccessRequestList,
  PropertyAccessRequestEdit,
} from './propertyAccessRequests';

import aws_exports from './aws-exports';
Amplify.configure(aws_exports);

const ApiUrl = process.env.REACT_APP_API_URL;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const auth = localStorage.getItem('auth');

  if (auth) {
    const accessToken = JSON.parse(auth).accessToken.jwtToken;
    options.headers.set('Authorization', `Bearer ${accessToken}`);
  }

  return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(`${ApiUrl}/api`, httpClient);



const App = () => (
  <Admin 
    authProvider={authProvider}
    dataProvider={dataProvider}
  >
    <Resource 
      name="skills" 
      list={SkillList} 
      edit={SkillEdit}
      create={SkillCreate}
    />
    <Resource
      name="verifications"
      list={VerificationList}
      create={VerificationCreate}
      icon={VerifiedIcon}
    />
    <Resource
      name="properties"
      list={PropertyList}
      edit={PropertyEdit}
      create={PropertyCreate}
      icon={HouseIcon}
    />
    <Resource
      name="property access requests"
      list={PropertyAccessRequestList}
      edit={PropertyAccessRequestEdit}
    />
  </Admin>
);

export default App;
