import React, { useState, useEffect } from 'react';
import { 
  AutocompleteInput,
  Create, 
  SimpleForm, 
  List, 
  Datagrid, 
  TextField,
  useDataProvider,
  Loading,
  Error
} from 'react-admin';

export const VerificationList = (props) => {

  const dataProvider = useDataProvider();

  const [profiles, setProfiles] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getMany('construction-profiles', {})
      .then((response) => {
        if (response && response.data) {
          const profiles = response.data;
          setProfiles(profiles);
        }

        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        setError(true);
      });
  }, [dataProvider]);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!profiles.length) return null;

  const getSkillName = (skillId) => profiles.reduce((acc, curr) => {
    return (curr.profile.skills.find(item => item.id === skillId) || {}).name || acc;
  }, undefined);

  const SkillField = ({ record, source }) => {
    return (
      <span>
        {getSkillName(record[source])}
      </span>
    );
  };

  const getProfile = (profileId) => profiles.reduce((acc, curr) => {
    const profile = curr.profile

    if (profile.id === profileId) {
      return `${curr.firstName} ${curr.surname} ${curr.email} ${curr.phoneNumber}`
    } else {
      return acc;
    }
  }, undefined);

  const ProfileField = ({ record, source }) => {
    return (
      <span>
        {getProfile(record[source])}
      </span>
    );
  };

  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <SkillField source="skill" />
        <ProfileField source="profile" />
      </Datagrid>
    </List>
  );
};

export const VerificationCreate = (props) => {

  const dataProvider = useDataProvider();

  const [profiles, setProfiles] = useState([]);
  const [skills, setSkills] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getMany('construction-profiles', {})
      .then((response) => {
        const profiles = response.data;
        setProfiles(profiles);

        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        setError(true);
      });
  }, [dataProvider]);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!profiles.length) return null;

  const choices = profiles.map(profile => ({
    id: profile.profile.id,
    name: `${profile.firstName} ${profile.surname} ${profile.email} ${profile.phoneNumber}`
  }));

  return (
    <Create {...props}>
      <SimpleForm>
        <AutocompleteInput 
          source="profile" 
          choices={choices} 
          onChange={(profileId) => {
            const newSkills = profiles.find(item => item.profile.id === profileId).profile.skills;
            setSkills(newSkills);
          }}
        />
        <AutocompleteInput 
          source="skill" 
          choices={skills} 
        />
      </SimpleForm>
    </Create>
  );
};
