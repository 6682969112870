import { React } from 'react';
import { ArrayField, ArrayInput, SimpleFormIterator, Create, Edit, SimpleForm, TextInput, List, Datagrid, TextField } from 'react-admin';

export const SkillList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <ArrayField source="questions">
              <Datagrid>
                <TextField source="question" />
              </Datagrid>
            </ArrayField>
        </Datagrid>
    </List>
);

export const SkillEdit = props => (
  <Edit {...props}>
      <SimpleForm>
          <TextInput source="name" />
          <ArrayInput source="questions">
            <SimpleFormIterator>
              <TextInput source="question" />
            </SimpleFormIterator>
          </ArrayInput>
      </SimpleForm>
  </Edit>
);

export const SkillCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="name" />
          <ArrayInput source="questions">
            <SimpleFormIterator>
              <TextInput source="question" />
            </SimpleFormIterator>
          </ArrayInput>
      </SimpleForm>
  </Create>
);
