import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  Edit,
} from 'react-admin';

export const PropertyAccessRequestList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
    </Datagrid>
  </List>
);

export const PropertyAccessRequestEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>

    </SimpleForm>
  </Edit>
);
