import { Auth } from 'aws-amplify';

export const authProvider = {
  login: async ({ username, password }) =>  {
    await Auth.signIn({
      username: username,
      password
    })

    const auth = await Auth.currentSession();
    console.log('auth', auth);

    localStorage.setItem('auth', JSON.stringify(auth));
  },
  checkError: (error) => {
    console.log('error', error);

    const status = error.status;

    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }

    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: (params) => {
    console.log('checkAuth/params', params);

    const auth = localStorage.getItem('auth');

    if (auth) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },
  logout: () => {
    console.log('logout');

    return Promise.resolve();
  },
  // authorization
  getPermissions: (params) => {
    console.log('getPermissions/params', params);

    return Promise.reject();
  },
}

export default authProvider;
